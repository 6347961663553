<template>
  <div id="user-container" v-if="accessibleRoutes.log">
    <div id="user-btn-group" no-gutters>
      <div class="col-12 p-responsive">
        <div class="col-12">
          <v-btn-toggle v-model="toggle">
            <router-link to="/logs/users">
              <v-btn :ripple="false" :disabled="toggle == 0"> Users Logs </v-btn>
            </router-link>
            <router-link to="/logs/devices">
              <v-btn :ripple="false" :disabled="toggle == 1" class="tab-3-menu">
                Devices Logs</v-btn
              ></router-link
            >
          </v-btn-toggle>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0 pb-0">
      <router-view></router-view>
    </div>
  </div>
</template>
<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
export default {
  data() {
    return {
      toggle: 0,
    };
  },
  created() {
    this.toggle = 0;
    if (this.$router.currentRoute.name == "Devices Logs") {
      this.toggle = 1;
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    }
  },
};
</script>
